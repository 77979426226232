



























































































































































































































































import { toTitlecase } from "@/helpers/common";
import { debounce, debounceProcess } from "@/helpers/debounce";
import {
  Row,
  useAsset,
  useAssetTag,
  useBlob,
  useContactData,
  useLocalFilter,
  useMapMasterTypeToOptionAlt,
  useReportUnitPosition,
  useSort,
} from "@/hooks";
import { FilterField } from "@/hooks/useReportUnitPosition";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { OptionModel } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataWarehouse,
  ResponseWarehouse,
} from "@/models/interface/logistic.interface";
import { ResponseReportUnitPosition } from "@/models/interface/salesOrder.interface";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { SortDirection, SorterProps } from "@/types";
import { ListContactDataDto } from "@interface/contact-data";
import { FormModel } from "ant-design-vue";
import moment from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

@Component({
  components: {
    DatePicker: DatePicker,
  },
})
export default class ReportOfUnitPosition extends Vue {
  @Ref("form") form!: FormModel;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  useLocalFilter = useLocalFilter;
  filterForm = {
    branch: "",
    customerName: "",
    warehouse: "",
    asOfDate: moment().format(DEFAULT_DATE_FORMAT),
    status: [] as string[],
    yearPlacement: "",
    category: "",
    unitCode: "",
    merk: "",
    assetType: "",
    serialNumber: "",
    custLoc: "", // customer location
  };
  formRules = {
    branch: [{ required: false }],
    customerName: [{ required: false }],
    warehouse: [{ required: false }],
    asOfDate: [{ required: false }],
    status: [{ required: false }],
    yearPlacement: [{ required: false }],
    category: [{ required: false }],
    unitCode: [{ required: false }],
    merk: [{ required: false }],
    serialNumber: [{ required: false }],
    assetType: [{ required: false }],
    custLoc: [{ required: false }],
  };
  branchOption = {
    data: [] as DataWarehouse[],
    search: "",
    fetching: true,
  };
  categoryOption = {
    data: [] as any[],
    search: "",
    fetching: true,
  };
  warehouseOption = {
    data: [] as OptionModel[],
    search: "",
    fetching: true,
  };
  statusOption = {
    data: [] as Option[],
    search: "",
    fetching: false,
  };
  customerNameOption = {
    data: [] as ListContactDataDto[],
    search: "",
    fetching: true,
  };
  merkOption = {
    data: [] as string[],
    search: "",
    fetching: true,
  };
  tableColumns = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      width: 70,
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      width: 150,
      scopedSlots: { customRender: "nullable" },
      sorter: true,
    },
    {
      title: this.$t("lbl_year_placement"),
      dataIndex: "yearPlacement",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      width: 100,
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "merk",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_model"),
      dataIndex: "model",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_capacity"),
      dataIndex: "capacity",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_serial_number_short"),
      dataIndex: "serialNumber",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "specification",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_active_status"),
      dataIndex: "status",
      width: 150,
      customRender: text => toTitlecase(text),
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      width: 170,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_code"),
      dataIndex: "customerCode",
      width: 170,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
      width: 170,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_warehouse_spp"),
      dataIndex: "warehouse",
      width: 200,
      scopedSlots: { customRender: "nullable" },
    },
  ];
  dataListItems: Row<ResponseReportUnitPosition>[] = [];
  loading = {
    download: false,
    find: false,
    assetType: false,
  };
  query: RequestQueryParamsModel = {
    search: "",
    sorts: "",
  };

  optAssetType: Option[] = [];
  optCustLoc: Option[] = [];

  created(): void {
    this.onSearchAssetType = debounceProcess(this.onSearchAssetType);
  }

  mounted(): void {
    this.getBranchList();
    this.getWarehouseList();
    this.getCustomerName();
    this.getCategory();
    this.fetchAssetType(new RequestQueryParams());
    this.fetchAssetStatus();
  }

  getCategory() {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 100,
    };
    if (this.categoryOption.search) {
      params.search = `segment.first~*${this.categoryOption.search}*`;
    }
    this.categoryOption.fetching = true;
    assetsServices
      .listCategory(params)
      .then(response => {
        this.categoryOption.data = response.data
          .map(dataMap => {
            return {
              segment1: dataMap.segments[0].first,
              id: dataMap.segments[0].first,
            };
          })
          .filter(
            (value, index, self) =>
              index === self.findIndex(t => t.segment1 === value.segment1)
          );
      })
      .finally(() => (this.categoryOption.fetching = false));
  }
  findData(): void {
    const { filterBy } = useReportUnitPosition();
    this.form.validate((valid: boolean) => {
      if (!valid) return;
      const field: FilterField = {
        branchId: this.filterForm.branch,
        categoryId: this.filterForm.category.toLowerCase(),
        warehouseId: this.filterForm.warehouse,
        brand: this.filterForm.merk,
        unitCode: this.filterForm.unitCode,
        assetType: this.filterForm.assetType,
        serialNumber: this.filterForm.serialNumber,
        customerLocation: this.filterForm.custLoc,
        customerId: this.filterForm.customerName,
        status: this.filterForm.status,
      };

      if (this.filterForm.yearPlacement) {
        field.yearPlacement = moment(this.filterForm.yearPlacement);
      }
      if (this.filterForm.asOfDate) {
        field.asOfDate = moment(this.filterForm.asOfDate);
      }
      this.query.search = filterBy(field);
      this.getReportUnitPosition();
    });
  }

  resetForm(): void {
    this.form.resetFields();
    this.filterForm.status = [];
  }

  getBranchList(): void {
    this.branchOption.fetching = true;
    const param: RequestQueryParamsModel = {
      limit: 20,
      page: 0,
      search: ``,
    };
    if (this.branchOption.search) {
      param.search = `name~*${this.branchOption.search}*`;
    }
    logisticServices
      .listWarehouseBranch(param, "")
      .then((res: ResponseWarehouse) => {
        this.branchOption.data = res.data;
      })
      .finally(() => (this.branchOption.fetching = false));
  }

  getWarehouseList(): void {
    const params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    if (this.warehouseOption.search) {
      params.search = `branchWarehouse.name~*${this.warehouseOption.search}*_OR_name~*${this.warehouseOption.search}*`;
    }
    this.warehouseOption.fetching = true;
    logisticServices
      .listWarehouse(params, "")
      .then((res: ResponseWarehouse) => {
        this.warehouseOption.data = res.data.map(d => {
          return {
            label: d.name,
            value: d.id,
          };
        });
      })
      .finally(() => (this.warehouseOption.fetching = false));
  }
  getCustomerName(): void {
    this.customerNameOption.fetching = true;
    const searchName: string = this.customerNameOption.search
      ? `_AND_firstName~*${this.customerNameOption.search}*_OR_lastName~*${this.customerNameOption.search}*`
      : "";
    const param: RequestQueryParamsModel = {
      page: 0,
      limit: 20,
      search: `customer~true_AND_active~true${searchName}`,
    };
    contactServices
      .listContactData(param)
      .then(res => {
        this.customerNameOption.data = res.data;
      })
      .finally(() => (this.customerNameOption.fetching = false));
  }

  getReportUnitPosition(): void {
    const { findAll, toTableRow } = useReportUnitPosition();
    const params: RequestQueryParamsModel = {
      sorts: "createdDate:desc",
      search: this.query.search,
    };

    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.dataListItems = toTableRow(res);
      })
      .finally(() => (this.loading.find = false));
  }

  /**
   * @param value customer secure id
   */
  onChangeCustomer(value = ""): void {
    this.filterForm.customerName = value;

    this.findCustLoc(value);
  }

  findCustLoc(custId: string): void {
    const { toContactLocationOptions } = useContactData();
    const cust = this.customerNameOption.data.find(e => e.id === custId);
    const addresses =
      cust?.addressDataList.filter(
        e => e.shipTo && e.location && e.location !== "-"
      ) || [];
    this.optCustLoc = toContactLocationOptions(addresses);
  }

  handleChangeDropdown(type, value = ""): void {
    if (type === "branch") {
      this.filterForm.branch = value;
      this.filterForm.warehouse = "";
      const branch = this.branchOption.data.find(item => item.id == value);
      this.warehouseOption.search = branch?.name || "";

      this.getWarehouseList();
    }
  }

  searchDropdown(type, value) {
    switch (type) {
      case "branch":
        this.branchOption.search = value;
        debounce(() => {
          this.getBranchList();
        }, 200);
        break;
      case "category":
        this.categoryOption.search = value;
        debounce(() => {
          this.getCategory();
        }, 200);
        break;
      case "warehouse":
        this.warehouseOption.search = value;
        debounce(() => {
          this.getWarehouseList();
        }, 200);
        break;
      case "customerName":
        this.customerNameOption.search = value;
        debounce(() => {
          this.getCustomerName();
        }, 200);
        break;
      default:
        break;
    }
  }
  downloadReport(): void {
    const { download } = useReportUnitPosition();
    const { toDownload } = useBlob();
    this.form.validate((valid: boolean) => {
      if (!valid) return;
      const {
        customerName: customerId,
        asOfDate,
        assetType,
        merk,
        unitCode,
        custLoc,
        warehouse: warehouseId,
        category,
        serialNumber,
        yearPlacement,
        branch: branchId,
        status = [],
      } = this.filterForm;

      const header = {
        merk,
        asOfDate,
        unitCode,
        branch: "",
        status: "",
        category: "",
        year: yearPlacement ? moment(yearPlacement).get("year") : "",
        warehouse: "",
        customerName: "",
      };

      header.branch =
        this.branchOption.data.find(e => e.id === branchId)?.name || "";

      header.status = status.join("&");

      header.category =
        this.categoryOption.data.find(e => e.id === category)?.segment1 || "";
      header.warehouse =
        this.warehouseOption.data.find(e => e.value === warehouseId)?.label ||
        "";
      header.customerName =
        this.customerNameOption.data.find(e => e.id === customerId)
          ?.firstName || "";

      const company =
        this.$store.state.authStore.authData.companyName ||
        "PT. SATRIA PIRANTI PERKASA";

      const headers = [
        company,
        header.branch || "ALL",
        header.status || "ALL",
        header.category || "ALL",
        header.year || "ALL",
        header.warehouse || "ALL",
        header.merk || "ALL",
        header.asOfDate || "ALL",
        header.unitCode || "ALL",
        header.customerName || "ALL",
        assetType || "ALL",
        custLoc || "ALL",
        serialNumber || "ALL",
      ];

      const params = new RequestQueryParams();
      params.params = headers.join(",");
      params.sorts = this.query.sorts;

      if (this.query.search) params.search = this.query.search;

      this.loading.download = true;
      download(params)
        .then(res => {
          toDownload(res, "report_unit_position.xlsx");
        })
        .finally(() => (this.loading.download = false));
    });
  }

  fetchAssetType(params: RequestQueryParamsModel): void {
    const { findAllType, toOptions } = useAssetTag();
    this.loading.assetType = true;
    findAllType(params)
      .then(res => {
        this.optAssetType = toOptions(res.data);
      })
      .finally(() => {
        this.loading.assetType = false;
      });
  }

  onSearchAssetType(search = ""): void {
    const { filterBy } = useAssetTag();
    const params = new RequestQueryParams();
    params.search = filterBy({ value: search });

    this.fetchAssetType(params);
  }

  fetchAssetStatus(): void {
    const { findAllAssetStatus } = useAsset();
    this.statusOption.fetching = true;
    findAllAssetStatus()
      .then(res => {
        this.statusOption.data = useMapMasterTypeToOptionAlt(res);
      })
      .finally(() => {
        this.statusOption.fetching = false;
      });
  }

  onTableChange(_pagination, _filter, sorter: SorterProps): void {
    const { toTableRow } = useReportUnitPosition();
    const { sortStr } = useSort();
    const { order = "", columnKey } = sorter;

    this.query.sorts = "";
    if (order === "ascend") {
      this.query.sorts = "unitCode:asc";
    } else if (order === "descend") {
      this.query.sorts = "unitCode:desc";
    }

    const copy = [...this.dataListItems];
    this.dataListItems = copy.sort((a, b) =>
      sortStr(a[columnKey], b[columnKey], order as SortDirection)
    );
    this.dataListItems = toTableRow(copy);
  }
}
