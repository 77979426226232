var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_report_of_units_position") } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.filterForm,
                rules: _vm.formRules,
                "label-align": "left",
                "label-col": { span: 6 },
                "wrapper-col": { span: 10 }
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.findData.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_branch"), prop: "branch" }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                allowClear: true,
                                loading: _vm.branchOption.fetching
                              },
                              on: {
                                change: function(value) {
                                  return _vm.handleChangeDropdown(
                                    "branch",
                                    value
                                  )
                                },
                                search: function(value) {
                                  return _vm.searchDropdown("branch", value)
                                }
                              },
                              model: {
                                value: _vm.filterForm.branch,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "branch", $$v)
                                },
                                expression: "filterForm.branch"
                              }
                            },
                            _vm._l(_vm.branchOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.name + "~" + index,
                                  attrs: { value: item.id }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s("" + item.name) + " "
                                        )
                                      ]),
                                      _vm._v(" " + _vm._s("" + item.name) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_category"),
                            prop: "category"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                allowClear: true,
                                loading: _vm.categoryOption.fetching
                              },
                              on: {
                                change: function(value) {
                                  return _vm.handleChangeDropdown(
                                    "category",
                                    value
                                  )
                                },
                                search: function(value) {
                                  return _vm.searchDropdown("category", value)
                                }
                              },
                              model: {
                                value: _vm.filterForm.category,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "category", $$v)
                                },
                                expression: "filterForm.category"
                              }
                            },
                            _vm._l(_vm.categoryOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.name + "~" + index,
                                  attrs: { value: item.id }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s("" + item.segment1) + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s("" + item.segment1) + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_warehouse"),
                            prop: "warehouse"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                allowClear: true,
                                loading: _vm.warehouseOption.fetching
                              },
                              on: {
                                change: function(value) {
                                  return _vm.handleChangeDropdown(
                                    "warehouse",
                                    value
                                  )
                                },
                                search: function(value) {
                                  return _vm.searchDropdown("warehouse", value)
                                }
                              },
                              model: {
                                value: _vm.filterForm.warehouse,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "warehouse", $$v)
                                },
                                expression: "filterForm.warehouse"
                              }
                            },
                            _vm._l(_vm.warehouseOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.name + "~" + index,
                                  attrs: { value: item.value }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s("" + item.label) + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s("" + item.label) + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_as_of_date"),
                            prop: "asOfDate"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              name: _vm.$t("lbl_as_of_date"),
                              placeholder: _vm.$t("lbl_as_of_date"),
                              autocomplete: "off",
                              disabled: true
                            },
                            model: {
                              value: _vm.filterForm.asOfDate,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "asOfDate", $$v)
                              },
                              expression: "filterForm.asOfDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_serial_number"),
                            prop: "serialNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { "allow-clear": "" },
                            model: {
                              value: _vm.filterForm.serialNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "serialNumber", $$v)
                              },
                              expression: "filterForm.serialNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_type"),
                            prop: "assetType"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                loading: _vm.loading.assetType,
                                "show-search": "",
                                "allow-clear": "",
                                "filter-option": false
                              },
                              on: { search: _vm.onSearchAssetType },
                              model: {
                                value: _vm.filterForm.assetType,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "assetType", $$v)
                                },
                                expression: "filterForm.assetType"
                              }
                            },
                            _vm._l(_vm.optAssetType, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(item.label) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(item.label) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_name"),
                            prop: "customerName"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                allowClear: true,
                                loading: _vm.customerNameOption.fetching
                              },
                              on: {
                                change: _vm.onChangeCustomer,
                                search: function(value) {
                                  return _vm.searchDropdown(
                                    "customerName",
                                    value
                                  )
                                }
                              },
                              model: {
                                value: _vm.filterForm.customerName,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "customerName", $$v)
                                },
                                expression: "filterForm.customerName"
                              }
                            },
                            _vm._l(_vm.customerNameOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.fullName + "~" + index,
                                  attrs: { value: item.id }
                                },
                                [_vm._v(" " + _vm._s(item.fullName) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_location"),
                            prop: "custLoc"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                "option-filter-prop": "children",
                                filterOption: _vm.useLocalFilter,
                                allowClear: true
                              },
                              model: {
                                value: _vm.filterForm.custLoc,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "custLoc", $$v)
                                },
                                expression: "filterForm.custLoc"
                              }
                            },
                            _vm._l(_vm.optCustLoc, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c("a-tooltip", [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title"
                                      },
                                      [_vm._v(_vm._s(item.label))]
                                    ),
                                    _vm._v(" " + _vm._s(item.label) + " ")
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_status"), prop: "status" }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: _vm.useLocalFilter,
                                "option-filter-prop": "children",
                                allowClear: true,
                                loading: _vm.statusOption.fetching,
                                mode: "multiple"
                              },
                              model: {
                                value: _vm.filterForm.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "status", $$v)
                                },
                                expression: "filterForm.status"
                              }
                            },
                            _vm._l(_vm.statusOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.label + "~" + index,
                                  attrs: { value: item.value }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(item.label) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(item.label) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_year_placement"),
                            prop: "yearPlacement"
                          }
                        },
                        [
                          _c("date-picker", {
                            attrs: { type: "year" },
                            model: {
                              value: _vm.filterForm.yearPlacement,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "yearPlacement", $$v)
                              },
                              expression: "filterForm.yearPlacement"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_brand"), prop: "merk" } },
                        [
                          _c("merk-select", {
                            model: {
                              value: _vm.filterForm.merk,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "merk", $$v)
                              },
                              expression: "filterForm.merk"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_unit_code"),
                            prop: "unitCode"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { "allow-clear": "" },
                            model: {
                              value: _vm.filterForm.unitCode,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "unitCode", $$v)
                              },
                              expression: "filterForm.unitCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-button",
                        { staticClass: "mr-2", on: { click: _vm.resetForm } },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.find,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              dataSource: _vm.dataListItems,
              columns: _vm.tableColumns,
              pagination: {
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", { total: total })
                },
                showSizeChanger: true,
                pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
              },
              size: "small",
              scroll: { x: 1300 }
            },
            on: { change: _vm.onTableChange },
            scopedSlots: _vm._u([
              {
                key: "currency",
                fn: function(text) {
                  return _c("span", {}, [
                    _vm._v(_vm._s(_vm._f("currency")(text)))
                  ])
                }
              },
              {
                key: "number",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
                }
              },
              {
                key: "date",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
                }
              },
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "mt-2 text-right" },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.download,
                    icon: "download"
                  },
                  on: { click: _vm.downloadReport }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }